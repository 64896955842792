import React from "react";

const PageNotFound = () => {
  return (
    <>
      <h1>Sorry page not found</h1>
    </>
  );
};

export default PageNotFound;
