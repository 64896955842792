import React, { useState, useEffect } from "react";
import axios from "axios";

const OpenDeltaSignUp = (props) => {
    
    const first_name = useInputFormField()
    const last_name = useInputFormField()
    const email = useInputFormField()
    const countrycode = useInputFormField()
    const phonenumber = useInputFormField()
    const domain = useInputFormField()
    const companyname = useInputFormField()
    const country = useInputFormField()
    const plan = useOptionPlan()
    const message = useInputFormField()

    const apiRegisterTenant = async (tenantData) => {
        const response = await axios.post(
            "https://8gwazujpyf.execute-api.us-west-2.amazonaws.com/prod"  + '/api/aws/register_tenant',
            tenantData,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
        return response
    }

    const apiSendEmailVerificationLink = async (email_address) => {
        const response = await axios.post(
            'https://2b3eco5gkh.execute-api.us-west-2.amazonaws.com/Prod/verify_email',
            { email_address },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
        return response
    }


    const handleSubmit = async (e) => {
        e.preventDefault()
        const isAllReqField = email.value !== "" && phonenumber.value !== "" && countrycode.value !== "";

        if (isAllReqField) {
            const data = {
                email: email.value,
                phone_number: countrycode.value + phonenumber.value,
                first_name: first_name.value,
                last_name: last_name.value,
                'domain': domain.value,
                'companyname': companyname.value,
                'country': country.value,
                'plan': plan.value,
                "message": message.value
            }
            console.log(data)
            const resp_reg = await apiRegisterTenant({
                attributes: data
            })
            if (resp_reg.data.success){
                alert("Your registration request has been received successfully. To complete the registration process, please check your email and verify your email address")
                const resp_email_verify = await apiSendEmailVerificationLink(email.value)
                // console.log(resp_email_verify)
            }
            // console.log(resp_reg)
        } else {
            alert("Fill all required Fields")
        }
    }
    return (
                <div className="row" style={{backgroundColor: "#dfdfdf"}}>
                    <div className="col-sm-12 mx-auto mt-5">
                        <div className="p-2" style={{backgroundColor: "#dfdfdf"}}>
                            <div className="mt-0 mb-4">
                                <h5 className="text-center font-weight-bold">Welcome</h5>
                                <h4 className="text-center font-weight-bold">Register Here</h4>
                            </div>
                            <div class="card bg-trans" style={{backgroundColor: "#dfdfdf"}}>
                                <div class="form-card">
                                    <div className="col-sm-12 mt-4 p-2">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">First Name <span className="text-red star-form">*</span></label>
                                            <input
                                                className="form-control"
                                                name="FirstName"
                                                type="text"
                                                value={first_name.value}
                                                onChange={first_name.onChange}
                                                placeholder="First Name"
                                                required
                                            />
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Last Name <span className="text-red star-form">*</span></label>
                                            <input
                                                className="form-control"
                                                name="FirstName"
                                                type="text"
                                                value={last_name.value}
                                                onChange={last_name.onChange}
                                                placeholder="Last Name"
                                                required
                                            />
                                        </div>
                                        <div class="form-group">
                                            <label for="">Company Name <span className="text-red star-form">*</span></label>
                                            <input
                                                className="form-control"
                                                name="Email"
                                                type="text"
                                                value={companyname.value}
                                                onChange={companyname.onChange}
                                                placeholder="Company Name"
                                                required
                                            />
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Company Email <span className="text-red star-form">*</span></label>
                                            <input
                                                className="form-control"
                                                name="Email"
                                                type="text"
                                                value={email.value}
                                                onChange={email.onChange}
                                                placeholder="Company Email"
                                                required
                                            />
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleInputPassword1">Country <span className="text-red star-form">*</span></label>
                                            <select
                                                className="form-control"
                                                name="Country"
                                                type="text"
                                                value={country.value}
                                                onChange={country.onChange}
                                                placeholder="country"
                                                required
                                            >
                                                <option selected value="US">US</option>
                                                <option selected value="India">India</option>
                                            
                                            </select>
                                        </div>
                                        <label for="exampleInputPassword1">Phone Number <span className="text-red star-form">*</span></label>
                                        <div className="d-flex">
                                            <select
                                                style={{height: "36px", width: "50px", borderRadius: "10%", borderColor: "#ffffff"}}
                                                name="code"
                                                type="text"
                                                value={countrycode.value}
                                                onChange={countrycode.onChange}
                                                placeholder="+1"
                                                required
                                            >
                                                <option selected value="+1">+1</option>
                                                <option selected value="+91">+91</option>
                                            
                                            </select>
                                            <input
                                                class="form-control ml-3"
                                                name="PhoneNo"
                                                type="text"
                                                value={phonenumber.value}
                                                onChange={phonenumber.onChange}
                                                placeholder="Phone No"
                                                required
                                            />
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleInputPassword1">Plan </label>
                                            <select
                                                className="form-control"
                                                name="Email"
                                                type="text"
                                                value={plan.value}
                                                onChange={plan.onChange}
                                                placeholder="Plan"
                                                required
                                            >
                                                <option selected value="Basic">Basic</option>
                                                <option selected value="Premium">Premium</option>
                                                <option selected value="EnterPrise">Enterprise</option>
                                            
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleFormControlTextarea1">Message</label>
                                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Type here..."
                                                value={message.value}
                                                onChange={message.onChange}
                                            ></textarea>
                                        </div>
                                        <div className="col-sm-12 d-flex justify-content-end mt-3">
                                            <button class="btn btn-blue btn-primary ml-5 float-right mr-3" onClick={(e)=> handleSubmit(e)}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    );
}

export default OpenDeltaSignUp;

const useInputFormField = () => {
    const [value, setValue] = useState('')
    const onChange = (e) => {
        setValue(e.target.value)
    }
    return { value, onChange }
}
const useOptionFunction = () => {
    const [value, setValue] = useState('Portfolio Management')
    const onChange = (e) => {
        setValue(e.target.value)
    }
    return { value, onChange }
}

const useOptionPlan = () => {
    const [value, setValue] = useState('Trial')
    const onChange = (e) => {
        setValue(e.target.value)
    }
    return { value, onChange }
}

const useOptionTitle = () => {
    const [value, setValue] = useState('founder')
    const onChange = (e) => {
        setValue(e.target.value)
    }
    return { value, onChange }
}


