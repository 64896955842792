import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import parse from "html-react-parser";
import { Helmet } from 'react-helmet';

const SingleNews = () => {
  const { id } = useParams();

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [img, setImg] = useState("");
  const [createdAt, setcreatedAt] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    const get = async () => {
      const res = await Axios.get("https://backend.razoredgeanalytics.com/news-get/" + id);
      setTitle(res.data.title);
      setDesc(res.data.desc);
      setImg(res.data.img);
      setcreatedAt(res.data.createdAt);
    };
    get();
  }, [id]);

  return (
    <>
      <div className="latest-post-empty-bg"></div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
        <meta property="og:url" content={"https://razoredgeanalytics.com/newsroom/"+ id} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:image" content={`https://backend.razoredgeanalytics.com/images/${img}`} />
        {/* Add more meta tags as needed */}
      </Helmet>
      <div className="post-sec my-5">
        <div className="container">
          <div className="row latest-post-rw">
            <div className="col-xl-12 col-lg-12 col-md-12">
              {!title.includes('HTML') && <div className="row latest-post-inner-rw">
                <div className="col-xl-6 col-lg-6 col-md-6 latest-post-sub-col">
                  <img
                    src={`https://backend.razoredgeanalytics.com/images/${img}`}
                    className="blogImage"
                    id="singleBCNImages"
                    alt="blogImage"
                  />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 m-auto">
                <div className="latest-post-txt">
                    <p>{new Date(createdAt).toDateString()}</p>
                    <h3>{title}</h3>
                  </div>
                </div>
              </div>}
              <div className="latest-post-para">
                <div>{parse(desc)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleNews;
