import React, { useEffect, useState } from "react";
import Axios from "axios";
import HeroSubSection from "./HeroSubSection";

const HeroSection = () => {
  const [hero, setHero] = useState([]);

  useEffect(() => {
    Axios.get("https://backend.razoredgeanalytics.com/home-get").then((res) => setHero(res.data));
  }, []);

  return (
    <>
      {hero.map((value, index) => (
        <>
        <HeroSubSection
          key={index}
          heroMainHeading={value.heroSection.heroMainHeading}
          herofirstbtn={value.heroSection.herofirstbtn}
          herofirstbtnUrl={value.heroSection.herofirstbtnUrl}
          herosecondbtn={value.heroSection.herosecondbtn}
          herosecondbtnUrl={value.heroSection.herosecondbtnUrl}
          herovideoimage={value.heroSection.herovideoimage}
          herosubheading={value.heroSection.herosubheading}
          heropera={value.heroSection.heropera}
          opendeltaprod={value.opendeltaprod}
        />
        <div className="col-sm-12 d-flex justify-content-center" style={{padding: "10px", backgroundColor: "#F1F0F3"}}>
            <h4>{value.heroSection.herosubheading}</h4>
        </div>
        </>
      ))}

    </>
  );
};

export default HeroSection;
