import React, { useEffect, useState, useRef } from "react";
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css'; // Import the CSS

Quill.register('modules/imageResize', ImageResize);

const CustomEditor = (props) => {
    const quillRef = useRef(null);
    const [editorHtml, setEditorHtml] = useState('');
  
    const handleEditorChange = (html) => {
      setEditorHtml(html);
    };

    const modules = {
      toolbar: { container : [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' }
        ],
        [{ align: [] }],
        ['link', 'image', 'video'],
        ['clean'],
        [{ 'color': [] }, { 'background': [] }]
      ]},
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false
      },
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
      }
    };
  
    const formats = [
      'header',
      'font',
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
      'video'
    ];

    useEffect(()=>{
      props.handleEditorChange(editorHtml)
    }, [editorHtml])

    return (
        <>
        <ReactQuill
            ref={quillRef}
            theme="snow"
            value={editorHtml}
            onChange={handleEditorChange}
            modules={modules}
            formats={formats}
            bounds={'#root'}
            placeholder="Enter text here..."
        />
        </>
    )
}

export default CustomEditor;