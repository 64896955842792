import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'
import Axios from "axios";
import OpenDeltaSignUp from "./SignUp";
import "./pricing.css"
import { red } from "@mui/material/colors";

const Pricing = () => {
    const [isRupee, setIsRupee] = useState(false);
    const location = useLocation();

    const handleStarted = () => {
        window.location.href=window.location.href.replace('pricing', 'register')
    }
  return (
    <>
        {location.pathname.split("/")[location.pathname.split("/").length - 1] == "register" && <div className="col-sm-12 p-1 m-0 d-flex justify-content-center signuppage">
            <div className="col-sm-4 m-0 p-0">
                <OpenDeltaSignUp />        
            </div>
        </div>}
        <div class="pricemain mt-3 mb-3">
            <table class="price-table">
                <tbody>
                    <tr>
                        <td class="price-blank"></td>
                        <td class="price-blank"></td>
                        <td class="price-table-popular">Most popular</td>
                        <td class="price-blank"></td>
                    </tr>
                    <tr class="price-table-head">
                        <td className="">
                            <div className="d-flex">
                                <label class="switch">
                                    <input type="checkbox" onClick={()=> setIsRupee(!isRupee)}>
                                    </input>
                                    <span class="slider round">
                                        <div className={isRupee ? "d-flex justify-content-left": "d-flex justify-content-end"}>
                                            {!isRupee && <i className="fas fa-dollar" style={{fontSize: "25px", marginRight: "10px"}}></i>}
                                            {isRupee && <i className="fas fa-rupee" style={{fontSize: "25px", marginLeft: "10px"}}></i>}

                                        </div>
                                    </span>
                                </label>
                            </div>
                        </td>
                        <td >
                            <p className="headtag">Basic</p>
                        </td>
                        <td >
                        <p className="headtag">Premium</p>
                        </td>
                        <td >
                           <p className="headtag">EnterPrise</p> 
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                                        <td class="price">
                                            
                                            <br/><p class="plan-price">{isRupee ? "₹0" : "$0"}<span class="plan-pricem">/mo</span></p>
                                            <br/>
                                            <a data-bs-toggle="modal" onClick={() => handleStarted()}>Get started</a>
                                        </td>
                                        <td class="price">
                                            
                                            <br/><p class="plan-price">{isRupee ? "₹45500" : "$549"}<span class="plan-pricem">/mo</span></p>
                                            <br/>
                                            <a data-bs-toggle="modal" onClick={() => handleStarted()}>Get started</a>
                                        </td>
                                        <td class="price">
                                            
                                            <br/><p class="plan-price"><span>Contact Sales</span></p>
                                            <br/>
                                            <a data-bs-toggle="modal" onClick={() => handleStarted()}>Contact Us</a>
                                        </td>
                    </tr>
                    <tr>
                        <td><a href="#wordpress-asset-updates" class="price-table-help"></a> Number of daily API calls <br /><p style={{fontSize: "12px"}}>{"(1 feature call is counted as 1 API call)"}</p></td>
                        <td>50</td>
                        <td>500</td>
                        <td>Unlimited</td>
                    </tr>
                    <tr>
                        <td><a href="#wordpress-core-updates" class="price-table-help"></a>Number of users</td>
                        <td>1</td>
                        <td>3</td>
                        <td>5+</td>
                    </tr>
                    <tr>
                        <td><a href="#wordpress-core-updates" class="price-table-help"></a>Max Number of Securities per Portfolio</td>
                        <td>15</td>
                        <td>100</td>
                        <td>Unlimited</td>
                    </tr>
                    <tr>
                        <td><a href="#wordpress-core-updates" class="price-table-help"></a>Number of Portfolios</td>
                        <td>Unlimited</td>
                        <td>Unlimited</td>
                        <td>Unlimited</td>
                    </tr>
                    <tr>
                        <td><a href="#wordpress-security-monitoring" class="price-table-help"></a>Custom Model Settings/Configurations </td>
                        <td><i class="fas fa-times"  style={{color: "red"}}></i></td>
                        <td><i class="fas fa-check"  style={{color: "green"}}></i></td>
                        <td><i class="fas fa-check"  style={{color: "green"}}></i></td>
                    </tr>
                    <tr>
                        <td><a href="#wordpress-uptime-monitoring" class="price-table-help"></a> Alpha AI</td>
                        <td><i class="fas fa-times"  style={{color: "red"}}></i></td>
                        <td><i class="fas fa-check"  style={{color: "green"}}></i></td>
                        <td><i class="fas fa-check"  style={{color: "green"}}></i></td>
                    </tr>
                    <tr>
                        <td><a href="#wordpress-uptime-monitoring" class="price-table-help"></a> Model Tuning</td>
                        <td><i class="fas fa-times"  style={{color: "red"}}></i></td>
                        <td><i class="fas fa-check"  style={{color: "green"}}></i></td>
                        <td><i class="fas fa-check"  style={{color: "green"}}></i></td>
                    </tr>
                    <tr>
                        <td><a href="#wordpress-malware-cleanup" class="price-table-help"></a> Onbaording Assistance</td>
                        <td><i class="fas fa-times"  style={{color: "red"}}></i></td>
                        <td><i class="fas fa-check"  style={{color: "green"}}></i></td>
                        <td><i class="fas fa-check"  style={{color: "green"}}></i></td>
                    </tr>
                    {['Portfolio Processing', 'Exposure,  Risk,  Scenario Analysis', 'Portfolio, Market and Factor Dashboards',
                     'AI powered Factor model', 'Multi Factor Screener', 'Portfolio Analyzer', 'Performance attribution'].map((ele) => {
                        return <tr>
                                <td><a href="#wordpress-security-audit" class="price-table-help"></a> {ele}</td>
                                <td><i class="fas fa-check"  style={{color: "green"}}></i></td>
                                <td><i class="fas fa-check"  style={{color: "green"}}></i></td>
                                <td><i class="fas fa-check"  style={{color: "green"}}></i></td>
                            </tr>
                     })}
                     <tr>
                        <td><a href="#wordpress-core-updates" class="price-table-help"></a>Backtesting</td>
                        <td>1Y</td>
                        <td>5Y</td>
                        <td>20Y</td>
                    </tr>
                    <tr>
                        <td><a href="#wordpress-core-updates" class="price-table-help"></a>Support hours</td>
                        <td>4 hours</td>
                        <td>Unlimited</td>
                        <td>Unlimited</td>
                    </tr>
                    <tr>
                        <td><a href="#wordpress-core-updates" class="price-table-help"></a>Support Methods</td>
                        <td>Chat+Email</td>
                        <td>Chat+Email</td>
                        <td>Chat+Email+Phone</td>
                    </tr>
                    <tr>
                        <td><a href="#wordpress-core-updates" class="price-table-help"></a>Quarterly Reviews</td>
                        <td><i class="fas fa-times"  style={{color: "red"}}></i></td>
                        <td><i class="fas fa-times"  style={{color: "red"}}></i></td>
                        <td><i class="fas fa-check"  style={{color: "green"}}></i></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td class="price">
                            <a data-bs-toggle="modal" onClick={() => handleStarted()}>Get started</a>
                        </td>
                        <td class="price">
                            <a data-bs-toggle="modal" onClick={() => handleStarted()}>Get started</a>
                        </td>
                        <td class="price">
                            <a data-bs-toggle="modal" onClick={() => handleStarted()}>Contact Us</a>
                        </td>
                    </tr>
                </tbody>
            </table>
            
        </div>
        </>
  );
};

export default Pricing;
