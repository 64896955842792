import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import BlogPage from "../adminComponents/Blog/BlogPage";

const AdminBlogsPage = () => {
  const [blogpage, setBlogpage] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    Axios.get("https://backend.razoredgeanalytics.com/blogPage-get").then((res) => setBlogpage(res.data));
  }, []);

  return (
    <>
      {blogpage.map((value, index) => (
        <BlogPage
          key={index}
          blogfirstheading={value.blogFirstSection.blogfirstheading}
          blogfirstpera={value.blogFirstSection.blogfirstpera}
          blogfirstsubheading={value.blogFirstSection.blogfirstsubheading}
          blogsecondheading={value.blogSecondSection.blogsecondheading}
          blogsecondbtntext={value.blogSecondSection.blogsecondbtntext}
          blogsecondpera={value.blogSecondSection.blogsecondpera}
          id={value._id}
        />
      ))}
      <Link
        to="/admin-blog-add"
        className="admin-add-new-caseStudies-btn"
      >
        Add New Blog
      </Link>
    </>
  );
};

export default AdminBlogsPage;
